.root{flex-grow: 1}
.input{
        display: flex !important;
        padding: 0px;
      }
      .SingleValue {
        font-size: 14px;
        padding-bottom: 4px;
        color:rgba(0, 0, 0, 0.87);
    }
      .valueContainer {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        padding-left: 0px;
        align-items: center;
        overflow:hidden;
      }
      .NoWrapValueContainer{
        display: flex;
        flex-wrap: nowrap;
        flex: 1;
        padding-left: 0px;
        align-items: center;
        overflow: hidden;  
      }
      .chip {
        margin:4px 2px;
        background-color: #e0e0e0;
        color: rgba(0, 0, 0, 0.87);
      }
      .chipFocused {
        background-color:#e0e0e0
      }
      .noOptionsMessage {
        padding:8px 16px;
      }
      .placeholder {
        position: absolute;
        left: 10px;
        font-size: 16px;
      }
      .paper {
        position: absolute ;
        z-index: 2 ;
        margin-top:6px ;
        left: 0px ;
        right: 0px;
      }